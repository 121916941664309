import Select from 'react-select';
import { Button, Modal, Form } from 'react-bootstrap';
import { useFGReportsFarmGlobal } from "./ReportsFarmGlobalContext.js";
import styles from './ReportsFarmGlobal.module.scss';
import { useState } from 'react';

const ModalExportFarmGlobal = () => {
  const { showExportModal, handleCloseExportModal, exportOptions, handleDataExport } = useFGReportsFarmGlobal();
  const [selectedSeason, setSelectedSeason] = useState(null);

  return (
    <>
      <Modal
        show={showExportModal}
        onHide={handleCloseExportModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Chemical File Links</Modal.Title>
        </Modal.Header>

        <Modal.Body className={styles.modal_body} style={{ alignItems: 'center' }}>
          <Form.Group style={{ width: '50%' }}>
            <Form.Label style={{ marginBottom: '10px', fontWeight: '500' }}>Please select a season</Form.Label>
            <Select
              options={exportOptions}
              value={selectedSeason}
              onChange={e => setSelectedSeason(e)}
              placeholder="Select a season"
              isClearable={true}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'center', gap: '30px' }}>
          <Button variant="secondary" style={{ width: '200px' }} onClick={handleCloseExportModal}>
            Back
          </Button>
          <Button variant="primary" style={{ width: '200px' }} onClick={() => handleDataExport(selectedSeason)} disabled={!selectedSeason}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalExportFarmGlobal;
