
import React from 'react';
import styles from './listar_imagens_preview.module.scss';

const ListarImagemPreview = (params) => {
  const { imagens } = params;
  return (
    <div className={styles.image_preview_container}>
      {
        imagens.map((item, index) => (
          <div key={index} className={styles.image_preview}>
            <img
              src={URL.createObjectURL(item.imagem)}
              alt={`preview_img_${index}`}
            />
          </div>
        ))
      }
    </div>
  );
}

export default ListarImagemPreview;
