import React from 'react';
import { useFGReportsFarmGlobal } from "./ReportsFarmGlobalContext.js";
import { Button, Form } from 'react-bootstrap';
import styles from './ReportsFarmGlobal.module.scss';
import ModalReportsFarmGlobal from './ModalReportsFarmGlobal.jsx';
import SelectInput from '../../Misc/SelectInput/SelectInput.jsx';
import Tabela from '../../Misc/Tabela/Tabela.jsx';
import ModalReportDocs from './fg_reports_modal_docs.jsx';
import ModalExportFarmGlobal from './fg_reports_modal_export.jsx';
import ModalReportRemoval from './fg_reports_modal_confirm_modal.jsx';

const ReportsFarmGlobalMain = () => {
  const { mainData, filterObj, listagem, handleSetFilterObj, handleSearch, handleShowCad, showCad, showDocsModal, handleOpenDocsModal, showExportModal, handeOpenExportModal, showConfirmDeleteModal } = useFGReportsFarmGlobal();

  const reportColumns = [
    {
      cell: row => {
        return (
          <Button
            style={{ fontWeight: '500' }}
            variant={'primary'}
            onClick={() => handleOpenDocsModal(row)}
            // disabled={}
          >
            Documents
          </Button>
        )
      }
    },
    { name: 'Season', selector: row => row.season },
    { name: 'Style', selector: row => row.style },
    { name: 'Supplier', selector: row => row.supplier },
    { name: 'Subsupplier', selector: row => row.subsupplier || '-'  }
  ]

  const customFilter = (option, searchText) => {
    if (!searchText) return true;
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  }

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      width: '168.5px',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#dee2e6' : '#dee2e6',
      boxShadow: state.isFocused ? '0 0 0 1px #dee2e6' : 'none',
      '&:hover': { borderColor: '#dee2e6' },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
    })
  }

  return (
    <div>
      <article className="d-flex align-items-center">
        <h3 className="p-3">Digital Product Passport</h3>
        <Button
          type="button"
          variant="info"
          className={styles.attachBtn}
          onClick={handleShowCad}
        >
          + Attach New Report
        </Button>

        <Button
          type="button"
          variant="info"
          className={styles.attachBtn}
          onClick={handeOpenExportModal}
        >
          Export Links
        </Button>
      </article>

      <article>
        <Form className={styles.formWrapper}>
          <SelectInput
            placeholder={'Season'}
            label={'Season'}
            filterOption={customFilter}
            onChange={e => handleSetFilterObj('season', e)}
            options={listagem.season}
            value={filterObj.season || null}
            customSelectStyles={customSelectStyles}
          />
          <Form.Group className={styles.formgroup}>
            Style
            <Form.Control
              type="text"
              placeholder={'Style'}
              className={styles.stylesInput}
              value={filterObj.style || ''}
              onChange={e => handleSetFilterObj('style', e.target.value)}
            />
          </Form.Group>
          <SelectInput
            placeholder={'Supplier'}
            label={'Supplier'}
            filterOption={customFilter}
            onChange={e => handleSetFilterObj('supplier', e)}
            options={listagem.supplier}
            value={filterObj.supplier || null}
            customSelectStyles={customSelectStyles}
          />
          <SelectInput
            placeholder={'SubSupplier'}
            label={'SubSupplier'}
            filterOption={customFilter}
            onChange={e => handleSetFilterObj('subsupplier', e)}
            options={listagem.subsupplier}
            value={filterObj.subsupplier || null}
            customSelectStyles={customSelectStyles}
          />
          <Button
            type="button"
            variant="primary"
            className={`${styles.filterBtn} ${styles.searchBtn}`}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Form>
      </article>

      <article style={{ margin: '20px 20px 20px' }}>
        <Tabela
          columns={reportColumns}
          data={mainData}
          pending={false}
          paginationPerPage={20}
          highlightOnHover={true}
        />
      </article>
      {showCad && <ModalReportsFarmGlobal />}
      {showDocsModal && <ModalReportDocs />}
      {showExportModal && <ModalExportFarmGlobal />}
      {showConfirmDeleteModal && <ModalReportRemoval />}
    </div>
  );
}

export default ReportsFarmGlobalMain;
