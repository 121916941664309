import { useState } from "react";
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useFGReportsFarmGlobal } from "./ReportsFarmGlobalContext.js";
import MaterialIcon from 'material-icons-react';
import styles from './ReportsFarmGlobal.module.scss';

const ModalArquivosFarmGlobal = (props) => {
  const { editMode } = props;
  const { chemicalTestFiles, setChemicalTestFiles, physicalTestFiles, setPhysicalTestFiles, inspectionFiles, setInspectionFiles } = useFGReportsFarmGlobal();

  const [testOptionsList] = useState([
    { label: 'Successful', value: true },
    { label: 'Unsuccessful', value: false }
  ]);

  const handleFileChange = (e, index, type) => {
    let arr = [];
    let setter = [];
    switch (type) {
      case 'C': arr = chemicalTestFiles; setter = setChemicalTestFiles; break;
      case 'P': arr = physicalTestFiles; setter = setPhysicalTestFiles; break;
      case 'I': arr = inspectionFiles; setter = setInspectionFiles; break;
    }
    const file = e.target.files[0];
    const updatedFiles = [...arr];
    updatedFiles[index].file = file;
    setter(updatedFiles);
  }

  const handleFileRemove = (index, type) => {
    let arr = [];
    let setter = [];
    switch (type) {
      case 'C': arr = chemicalTestFiles; setter = setChemicalTestFiles; break;
      case 'P': arr = physicalTestFiles; setter = setPhysicalTestFiles; break;
      case 'I': arr = inspectionFiles; setter = setInspectionFiles; break;
    }
    const updatedFiles = [...arr];
    updatedFiles[index].file = null;
    setter(updatedFiles);
  }

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '140px',
      borderColor: state.isFocused ? '#dee2e6' : '#dee2e6',
      boxShadow: state.isFocused ? '0 0 0 1px #dee2e6' : 'none',
      '&:hover': { borderColor: '#dee2e6' }
    })
  }

  return (
    <>
      <Form.Group style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '6px' }}>
          <Form.Label style={{ margin: '0px', fontWeight: '500' }}>Chemical Test</Form.Label>
          <div className={styles.vertical_separator} />
          <i
            className={styles.input_btn}
            onClick={() => setChemicalTestFiles([
              ...chemicalTestFiles,
              { file: null, result: null }
            ])}
          >
            <MaterialIcon icon="add_circle" size={20} color={'#007bff'} />
          </i>
        </div>

        {editMode && <div>No new chemical test files added</div>}

        {chemicalTestFiles.map((obj, index) => (
          <div key={`c_input_file_${index}`} className={styles.file_wrapper}>
            <div className={styles.custom_input_wrapper}>
              <span
                className={styles.input_filename}
                style={{ opacity: obj.file ? 1 : 0.4 }}
              >
                {obj.file ? obj.file.name : 'No file selected'}
              </span>

              <div className={styles.vertical_separator} />

              <label
                htmlFor={`fileInput-chemical-${index}`}
                className={styles.input_btn}
              >
                <MaterialIcon icon="upload" size={20} color={'#007bff'} />
              </label>

              <div className={styles.vertical_separator} />

              <label
                className={styles.input_btn}
                onClick={() => handleFileRemove(index, 'C')}
              >
                <MaterialIcon icon="cancel" size={20} color={'#DC3545'} />
              </label>
            </div>

            <input
              type="file"
              accept=".pdf"
              id={`fileInput-chemical-${index}`}
              style={{ display: 'none' }}
              onChange={e => handleFileChange(e, index, 'C')}
            />

            <Select
              options={testOptionsList}
              value={obj.result}
              onChange={(value) => {
                const updatedFiles = [...chemicalTestFiles];
                updatedFiles[index].result = value;
                setChemicalTestFiles(updatedFiles);
              }}
              placeholder="Result"
              styles={customSelectStyles}
            />

            {(index > 0 || editMode) && <label
              className={styles.input_btn_delete}
              onClick={() => {
                const updatedFiles = [...chemicalTestFiles];
                updatedFiles.splice(index, 1);
                setChemicalTestFiles([...updatedFiles]);
              }}
            >
              <MaterialIcon icon="delete" size={20} color={'#DC3545'} />
            </label>}
          </div>
        ))}
      </Form.Group>

      <div className={styles.separator} />

      <Form.Group style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '6px' }}>
          <Form.Label style={{ margin: '0px', fontWeight: '500' }}>Physical Test</Form.Label>
          <div className={styles.vertical_separator} />
          <i
            className={styles.input_btn}
            onClick={() => setPhysicalTestFiles([
              ...physicalTestFiles,
              { file: null, result: null }
            ])}
          >
            <MaterialIcon icon="add_circle" size={20} color={'#007bff'} />
          </i>
        </div>

        {(physicalTestFiles.length === 0 && !editMode) && <div>Physical test files are optional</div>}
        {editMode && <div>No new physical test files added</div>}

        {physicalTestFiles.map((obj, index) => (
          <div key={`p_input_file_${index}`} className={styles.file_wrapper}>
            <div className={styles.custom_input_wrapper}>
              <span
                className={styles.input_filename}
                style={{ opacity: obj.file ? 1 : 0.4 }}
              >
                {obj.file ? obj.file.name : 'No file selected'}
              </span>

              <div className={styles.vertical_separator} />

              <label
                htmlFor={`fileInput-physical-${index}`}
                className={styles.input_btn}
              >
                <MaterialIcon icon="upload" size={20} color={'#007bff'} />
              </label>

              <div className={styles.vertical_separator} />

              <label
                className={styles.input_btn}
                onClick={() => handleFileRemove(index, 'P')}
              >
                <MaterialIcon icon="cancel" size={20} color={'#DC3545'} />
              </label>
            </div>

            <input
              type="file"
              accept=".pdf"
              id={`fileInput-physical-${index}`}
              style={{ display: 'none' }}
              onChange={e => handleFileChange(e, index, 'P')}
            />

            <Select
              options={testOptionsList}
              value={obj.result}
              onChange={(value) => {
                const updatedFiles = [...physicalTestFiles];
                updatedFiles[index].result = value;
                setPhysicalTestFiles(updatedFiles);
              }}
              placeholder="Result"
              styles={customSelectStyles}
            />

            <label
              className={styles.input_btn_delete}
              onClick={() => {
                const updatedFiles = [...physicalTestFiles];
                updatedFiles.splice(index, 1);
                setPhysicalTestFiles([...updatedFiles]);
              }}
            >
              <MaterialIcon icon="delete" size={20} color={'#DC3545'} />
            </label>
          </div>
        ))}
      </Form.Group>

      <div className={styles.separator} />

      <Form.Group style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '6px' }}>
          <Form.Label style={{ margin: '0px', fontWeight: '500' }}>Inpection</Form.Label>
          <div className={styles.vertical_separator} />
          <i
            className={styles.input_btn}
            onClick={() => setInspectionFiles([
              ...inspectionFiles,
              { file: null, result: null }
            ])}
          >
            <MaterialIcon icon="add_circle" size={20} color={'#007bff'} />
          </i>
        </div>

        {(inspectionFiles.length === 0 && !editMode) && <div>Inpection files are optional</div>}
        {editMode && <div>No new inspection files added</div>}

        {inspectionFiles.map((obj, index) => (
          <div key={`i_input_file_${index}`} className={styles.file_wrapper}>
            <div className={styles.custom_input_wrapper}>
              <span
                className={styles.input_filename}
                style={{ opacity: obj.file ? 1 : 0.4 }}
              >
                {obj.file ? obj.file.name : 'No file selected'}
              </span>

              <div className={styles.vertical_separator} />

              <label
                htmlFor={`fileInput-inspection-${index}`}
                className={styles.input_btn}
              >
                <MaterialIcon icon="upload" size={20} color={'#007bff'} />
              </label>

              <div className={styles.vertical_separator} />

              <label
                className={styles.input_btn}
                onClick={() => handleFileRemove(index, 'I')}
              >
                <MaterialIcon icon="cancel" size={20} color={'#DC3545'} />
              </label>
            </div>

            <input
              type="file"
              accept=".pdf"
              id={`fileInput-inspection-${index}`}
              style={{ display: 'none' }}
              onChange={e => handleFileChange(e, index, 'I')}
            />

            <Select
              options={testOptionsList}
              value={obj.result}
              onChange={(value) => {
                const updatedFiles = [...inspectionFiles];
                updatedFiles[index].result = value;
                setInspectionFiles(updatedFiles);
              }}
              placeholder="Result"
              styles={customSelectStyles}
            />

            <label
              className={styles.input_btn_delete}
              onClick={() => {
                const updatedFiles = [...inspectionFiles];
                updatedFiles.splice(index, 1);
                setInspectionFiles([...updatedFiles]);
              }}
            >
              <MaterialIcon icon="delete" size={20} color={'#DC3545'} />
            </label>
          </div>
        ))}
      </Form.Group>
    </>
  )
}

export default ModalArquivosFarmGlobal;
