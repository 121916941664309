import { Modal, Button, Form } from "react-bootstrap";
import styles from '../pa_revisao.module.scss';
import { usePARevisao } from "../pa_revisao_context";
import Tabela from "../../../Misc/Tabela/Tabela";
import ListarImagemPreview from '../../../Misc/ListarImagens/listar_imagens_preview';

const PARevisaoModalProcessamento = () => {
  const { modalProcessamento, setModalProcessamento, direcionamentoRevisao, statusFinal, quantidadeTotalDirecionada, processarRevisao, referenciaSelecionada, precoTagDivergente, composicaoTagDivergente, combinacoesImagens } = usePARevisao();

  const direcionamento_columns = [
    { name: 'Tamanho', selector: row => row.grade.tamanho },
    { name: 'Quantidade', selector: row => row.quantidade },
    { name: 'Defeitos', selector: row => row.defeitos.map(item => item.label).join(' | ') },
    { name: 'Loc. Defeito', selector: row => row.localizacao_defeito.map(item => item.label).join(' | ') },
    {
      name: 'Direcionamento',
      selector: row => {
        return composicaoTagDivergente.key || precoTagDivergente.key ? '43 - DEVOLUÇÃO (REVISÃO)' : row.direcionamento.label
      },
      width: '30%'
    }
  ];

  return (
    <Modal
      show={modalProcessamento}
      centered
      size={'lg'}
      onHide={() => setModalProcessamento(false)}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header style={{ fontWeight: '500' }} closeButton>
        Resumo da Análise | NF: {referenciaSelecionada.nf_entrada} | Produto: {referenciaSelecionada.produto} | Cor: {referenciaSelecionada.cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body} style={{ fontWeight: '500' }}>
        <div className={styles.resumo_row}>
          <section className={styles.dados_analise_wrapper}>
            <div className={styles.dados_analise_item}>
              <label className={styles.sub_form_label}>Status Parcial da Análise</label>
              <div
                className={styles.status_box}
                style={{
                  backgroundColor: statusFinal.color
                }}
              >
                {statusFinal.label}
              </div>
            </div>

            <div className={styles.dados_analise_item_duplo}>
              <div>
                <label className={styles.sub_form_label}>Quantidade de Peças da Amostra</label>
                <span className={styles.font_blue_big}>{referenciaSelecionada.qtde_pecas_amostra}</span>
              </div>
              <div>
                <label className={styles.sub_form_label}>Quantidade Total de Peças Direcionadas</label>
                <span className={styles.font_blue_big}>{quantidadeTotalDirecionada}</span>
              </div>
            </div>

            <div
              style={{
                display: referenciaSelecionada.nf_segunda_qualidade || referenciaSelecionada.produto_de_couro ? 'block' : 'none'
              }}
              className={styles.dados_analise_item}
            >
              <label
                style={{ display: referenciaSelecionada.nf_segunda_qualidade ? 'block' : 'none' }}
                className={styles.sub_form_label}
              >
                Esta é uma nota fiscal de segunda qualidade - Portanto todas as peças deverão ser revisadas
              </label>
              <label
                style={{ display: referenciaSelecionada.produto_de_couro ? 'block' : 'none' }}
                className={styles.sub_form_label}
              >
                Esta é uma nota fiscal de couro - Portanto todas as peças deverão ser revisadas
              </label>
            </div>

            <div
              className={styles.separator}
              style={{ display: precoTagDivergente.key || composicaoTagDivergente.key ? 'block' : 'none' }}
            />

            <div
              className={styles.direcionamento_info_warn}
              style={{ display: precoTagDivergente.key || composicaoTagDivergente.key ? 'block' : 'none' }}
            >
              Você indicou que há divergências entre as informações da TAG e do produto - todos os direcionamentos serão automaticamente alterados para DEVOLUÇÃO durante o processamento.
            </div>
          </section>
        </div>

        {
          <>
            <div className={styles.separator} />
            <label className={styles.sub_form_label} style={{ textAlign: 'center' }}>Imagens Inseridas</label>
            <ListarImagemPreview imagens={combinacoesImagens} />
            <div className={styles.separator} />
          </>
        }

        <Tabela
          columns={direcionamento_columns}
          data={direcionamentoRevisao}
          pagination={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'success'}
          onClick={processarRevisao}
          style={{ margin: '0px auto' }}
          className={styles.footer_btn_processamento}
        >
          Processsar Análise
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoModalProcessamento;