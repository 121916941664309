import { Modal, Button, Form } from "react-bootstrap";
import Tabela from "../../../Misc/Tabela/Tabela";
import { usePARevisao } from "../pa_revisao_context";
import MaterialIcon from 'material-icons-react';
import styles from '../pa_revisao.module.scss'
import { isNullish } from "../../../../utils/misc_utils";
import Select from 'react-select';

const PARevisaoGrade = () => {
  const { modalRevisao, setModalDirecionamento, referenciaSelecionada, gradeRevisao, direcionamentoRevisao, toggleSemDefeitos, handleRemoverDirecionamento, checarSePossuiDefeitos, quantidadeTotalDirecionada, statusFinal, precoTagDivergente, setPrecoTagDivergente, abrirModalProcessamento, composicaoTagDivergente, setComposicaoTagDivergente, listaDefeitosComp, abrirModalInformacoes, setModalCancelamento, simularErroConexao, combinacoesImagens, setCombinacoesImagens } = usePARevisao();

  const grade_columns = [
    {
      cell: row => {
        const possuiDefeitos = checarSePossuiDefeitos(row.index_grade);
        return (
          <Button
            variant={possuiDefeitos ? 'danger' : row.sem_defeitos ? 'success' : 'outline-primary'}
            onClick={() => toggleSemDefeitos(row.index_grade)}
            disabled={possuiDefeitos}
          >
            {possuiDefeitos ? 'Defeitos Encontrados' : row.sem_defeitos ? 'Sem Defeitos' : 'Confirmar Sem Defeitos'}
          </Button>
        )
      }, width: '200px'
    },
    { name: 'Tamanho', selector: row => row.tamanho },
    { name: 'Quantidade Total', selector: row => row.quantidade_total },
    { name: 'Quantidade Direcionada', selector: row => row.quantidade_defeituosa },
  ];

  const grade_columns_conditional = [
    {
      when: row => checarSePossuiDefeitos(row.index_grade),
      style: row => ({ backgroundColor: row.index_grade % 2 === 0 ? '#FFCCCC80' : '#FFCCCC50' })
    },
    {
      when: row => !checarSePossuiDefeitos(row.index_grade) && row.sem_defeitos,
      style: row => ({ backgroundColor: row.index_grade % 2 === 0 ? '#CCFFDA80' : '#CCFFDA50' })
    }
  ];

  const direcionamento_columns = [
    {
      cell: (row, index) => {
        return (
          <div onClick={() => handleRemoverDirecionamento(row, index)}>
            <MaterialIcon icon="delete" size={20} color='#BB271A' />
          </div>
        )
      }, width: '60px'
    },
    { name: 'Tamanho', selector: row => row.grade.tamanho, width: '100px' },
    { name: 'Quantidade', selector: row => row.quantidade, width: '100px' },
    { name: 'Status', selector: row => row.status_defeito.label, width: '160px' },
    { name: 'Defeitos', selector: row => row.defeitos.map(item => item.label).join(' | ') },
    { name: 'Loc. Defeito', selector: row => row.localizacao_defeito.map(item => item.label).join(' | ') },
    {
      name: 'Direcionamento',
      selector: row => {
        return composicaoTagDivergente.key || precoTagDivergente.key ? '43 - DEVOLUÇÃO (REVISÃO)' : row.direcionamento.label
      }
    }
  ];

  const customFilter = (option, searchText) => {
    if (!searchText) return true;
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  }

  const handleValueMask = (value) => {
    value = value ? value.replace(/[^0-9]/g, '') : '0';
    const formattedValue = (parseFloat(value) / 100).toFixed(2);
    setPrecoTagDivergente({ key: true, value: formattedValue });
  }

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      combinacoesImagens[index].imagem = file;
      setCombinacoesImagens([...combinacoesImagens]);
    }
  }

  const removerImagem = (index) => {
    combinacoesImagens[index].imagem = null;
    setCombinacoesImagens([...combinacoesImagens]);
  }

  return (
    <Modal
      show={modalRevisao}
      centered
      fullscreen={true}
    >
      <Modal.Header style={{ fontWeight: '500' }}>
        Revisão {referenciaSelecionada.nf_segunda_qualidade ? '(Segunda Qualidade)' : ''} | NF: {referenciaSelecionada.nf_entrada} | Produto: {referenciaSelecionada.produto} | Cor: {referenciaSelecionada.cor_produto}
        {/* <Button
          variant={'danger'}
          className={styles.footer_btn_processamento}
          onClick={() => simularErroConexao(1000)}
        >
          Simular Desconexão
        </Button> */}
        <Button
          variant={'danger'}
          className={styles.footer_btn_processamento}
          onClick={() => setModalCancelamento(true)}
        >
          Cancelar Análise
        </Button>
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <section className={styles.dados_analise_wrapper}>
          <div className={styles.dados_analise_item}>
            <label className={styles.sub_form_label}>Status Parcial da Análise</label>
            <div
              className={styles.status_box}
              style={{
                backgroundColor: statusFinal.color
              }}
            >
              {statusFinal.label}
            </div>
          </div>

          <div className={styles.dados_analise_item_duplo}>
            <div>
              <label className={styles.sub_form_label}>Quantidade de Peças da Amostra</label>
              <span className={styles.font_blue_big}>{referenciaSelecionada.qtde_pecas_amostra}</span>
            </div>
            <div>
              <label className={styles.sub_form_label}>Quantidade Total de Peças Direcionadas</label>
              <span className={styles.font_blue_big}>{quantidadeTotalDirecionada}</span>
            </div>
          </div>

          <div
            style={{
              display: referenciaSelecionada.nf_segunda_qualidade || referenciaSelecionada.produto_de_couro ? 'block' : 'none'
            }}
            className={styles.dados_analise_item}
          >
            <label
              style={{ display: referenciaSelecionada.nf_segunda_qualidade ? 'block' : 'none' }}
              className={styles.sub_form_label}
            >
              Esta é uma nota fiscal de segunda qualidade - Portanto todas as peças deverão ser revisadas
            </label>
            <label
              style={{ display: referenciaSelecionada.produto_de_couro ? 'block' : 'none' }}
              className={styles.sub_form_label}
            >
              Esta é uma nota fiscal de couro - Portanto todas as peças deverão ser revisadas
            </label>
          </div>

          <div className={styles.separator}></div>

          <div className={styles.btn_preco_wrapper}>
            <label className={styles.sub_form_label}>
              Preencha o campo abaixo se houver divergência entre os valores da TAG do produto e no LINX
            </label>
            <label className={styles.sub_form_label}>
              <span>Preço LINX - R$ </span><span className={styles.font_blue_big}>{referenciaSelecionada.preco_produto}</span>
            </label>

            <Form.Group className={styles.dados_analise_divergencias}>
              <Form.Check
                type={'switch'}
                style={{ transform: 'scale(1.8)' }}
                onChange={() => setPrecoTagDivergente({ key: !precoTagDivergente.key, value: '' })}
                value={precoTagDivergente.key}
              />
              <Form.Control
                type={'text'}
                placeholder={'Preço Divergente TAG'}
                value={precoTagDivergente.value}
                onChange={e => handleValueMask(e.target.value)}
                style={{ minWidth: '150px', height: '38px', width: '33%' }}
                disabled={!precoTagDivergente.key}
                isInvalid={
                  parseFloat(precoTagDivergente.value) === parseFloat(referenciaSelecionada.preco_produto)
                  || (precoTagDivergente.key && isNullish(precoTagDivergente.value))
                }
              />
            </Form.Group>
          </div>

          <div className={styles.separator}></div>

          <div className={styles.btn_preco_wrapper}>
            <label className={styles.sub_form_label}>
              Preencha o campo abaixo se houver divergência entre as composições na TAG do produto e no LINX
            </label>
            <label className={styles.sub_form_label}>
              Composição LINX - <span className={styles.font_blue_big}>{referenciaSelecionada.composicao}</span>
            </label>

            <Form.Group className={styles.dados_analise_divergencias}>
              <Form.Check
                type={'switch'}
                style={{ transform: 'scale(1.8)', alignSelf: 'center' }}
                onChange={() => setComposicaoTagDivergente({
                  key: !composicaoTagDivergente.key,
                  defeitos: []
                })}
                value={composicaoTagDivergente.key}
              />

              <Select
                className={styles.select_comp}
                placeholder={'Defeitos Comp.'}
                value={composicaoTagDivergente.defeitos}
                onChange={e => setComposicaoTagDivergente({
                  key: true,
                  defeitos: [e]
                })}
                options={listaDefeitosComp}
                filterOption={customFilter}
                isDisabled={!composicaoTagDivergente.key}
                styles={{
                  control: base => ({
                    ...base,
                    borderColor: composicaoTagDivergente.key && composicaoTagDivergente.defeitos.length === 0 ? 'red' : base.borderColor,
                    '&:hover': {
                      borderColor: composicaoTagDivergente.key && composicaoTagDivergente.defeitos.length === 0 ? 'red' : base['&:hover'].borderColor
                    }
                  })
                }}
              />
            </Form.Group>
          </div>

          <div className={styles.separator} />

          <div className={styles.btn_preco_wrapper}>
            <label className={styles.sub_form_label}>Imagens</label>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'left', columnGap: '0.5%', rowGap: '10px' }}>
            {combinacoesImagens.length === 0 && (
              <label
                style={{ margin: 'auto', backgroundColor: '#B4E380', padding: '8px 16px', borderRadius: '10px' }}
                className={styles.sub_form_label}
              >
                Não é necessário inserir imagens quando não há defeitos na produção.
              </label>
            )}
            {combinacoesImagens.map((item, index) => {
              const combinationValues = Object.values(JSON.parse(item.combination));
              return (
                <div
                  key={`imagem_modal_${index}`}
                  className={styles.images_arr}
                >
                  <div
                    style={{ width: '100px', height: '100px', border: '1px solid #dee2e6', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', overflow: 'hidden' }}
                  >
                    <img
                      src={item.imagem ? URL.createObjectURL(item.imagem) : 'img/camera_icon.png'}
                      alt={`preview_${index}`}
                      width={item.imagem ? 100 : 40}
                      height={item.imagem ? 100 : 40}
                      style={{ opacity: item.imagem ? '1' : '0.4' }}
                    />
                    <label
                      htmlFor={`imageInput-${index}`}
                      className={styles.input_label}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      id={`imageInput-${index}`}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, index)}
                    />
                    {item.imagem && <div className={styles.remove_btn} onClick={() => removerImagem(index)}>
                      <MaterialIcon
                        icon={'cancel'}
                        size={20}
                        color='#DC3545'
                      />
                    </div>}
                  </div>
                  {combinationValues.map((item, combIndex) => {
                    return (
                      <div
                        key={`imagem_label_${index}_${combIndex}`}
                        className={styles.sub_form_label}
                        style={{ margin: combIndex === 0 ? '4px 0px 0px' : '0px', fontSize: '12px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center', padding: '0px 10px' }}
                      >
                        {item}
                      </div>
                    )
                  })}
                </div>
              );
            })}
          </div>
        </section>

        <section style={{ marginTop: '-16px' }}>
          <label className={styles.form_label}>Grade</label>
          <Tabela
            columns={grade_columns}
            data={gradeRevisao}
            pagination={false}
            conditionalRowStyles={grade_columns_conditional}
          />
        </section>

        <section>
          <label className={styles.form_label}>Direcionamento</label>
          <Tabela
            columns={direcionamento_columns}
            data={direcionamentoRevisao}
            pagination={false}
          />
        </section>
      </Modal.Body>
      <Modal.Footer className={styles.modal_footer}>
        <Button
          variant={'warning'}
          className={styles.footer_btn_direcionamento}
          onClick={() => setModalDirecionamento(true)}
        >
          Direcionar Peças Defeituosas {referenciaSelecionada.nf_segunda_qualidade ? '(Segunda Qualidade)' : ''}
        </Button>
        <Button
          variant={'success'}
          className={styles.footer_btn_processamento}
          onClick={() => abrirModalProcessamento()}
        >
          Resumo | Processar
        </Button>
        <Button
          variant={'primary'}
          className={styles.footer_btn_processamento}
          onClick={() => abrirModalInformacoes(referenciaSelecionada)}
        >
          Informações
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoGrade;
