import { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../../../services/api';
import { useMisc } from '../../../contexts/MiscContext';
import { toast } from 'react-toastify';
import CookiesService from '../../../services/cookies';
import ReportsFarmGlobalMain from './ReportsFarmGlobal.Main';
import axios from 'axios';

const FGReportsFarmGlobalContext = createContext();
export const useFGReportsFarmGlobal = () => useContext(FGReportsFarmGlobalContext);

export const FGReportsFarmGlobalProvider = () => {
  const { setShowLoading } = useMisc();
  const userLogin = CookiesService.getUserLogin();

  const [showCad, setShowCad] = useState(false);
  const [showDocsModal, setShowDocsModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportOptions, setExportOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedDocs, setSelectedDocs] = useState({});
  const [mainData, setMainData] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [blockedSeasons, setBlockedSeasons] = useState([]);
  const [listagem, setListagem] = useState({
    season: [],
    supplier: [],
    subsupplier: []
  });
  const [listagemSubsupplier, setListagemSubsupplier] = useState([]);

  const [filterObj, setFilterObj] = useState({
    season: null,
    supplier: null,
    subsupplier: null
  });

  const [formObj, setFormObj] = useState({
    style: '',
    season: null,
    supplier: null,
    subsupplier: null
  });
  const [styleInput, setStyleInput] = useState('');

  const [chemicalTestFiles, setChemicalTestFiles] = useState([{ file: null, result: null }]);
  const [physicalTestFiles, setPhysicalTestFiles] = useState([]);
  const [inspectionFiles, setInspectionFiles] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowLoading(true);
        const res = await api.get('/FGReportsFarmGlobal/reports-fg-filtro');
        setListagem({
          season: res.data.seasons,
          supplier: res.data.resSupplier,
          subsupplier: res.data.resSubSupplier
        });
      } catch (error) {
        toast.error('There has been an error during the initialization of the interface. Please refresh or contact support.');
      } finally {
        setShowLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleShowPDF = async (fileName) => {
    try {
      const res = await api.get('/FGReportsFarmGlobal/read-file/', { params: { fileName } });
      if (!res.data[0]) throw 10001;
      window.open(res.data[0], '_blank');
    } catch (e) {
      if (e === 10001) return toast.error('Sorry, we could not find that file. Please contact support.');
      toast.error('There has been an error fetching the file data. Please refresh or contact support.');
    }
  }

  const handleSetFilterObj = (key, value) => {
    setFilterObj(x => ({ ...x, [key]: value }));
  }

  const handleShowCad = (item) => {
    setShowCad(true);
  }

  const handleSearch = async () => {
    try {
      setShowLoading(true);

      const params = {}
      if (filterObj.season) params.season = filterObj.season.label;
      if (filterObj.supplier) params.supplier = filterObj.supplier.label;
      if (filterObj.subsupplier) params.subsupplier = filterObj.subsupplier.label;
      if (filterObj.style) params.style = filterObj.style;
      const res = await api.get('/FGReportsFarmGlobal/reports-fg-main-grid', { params });

      if (res.data.length === 0) {
        toast.warning('No results have been found.');
      } else {
        setMainData(res.data);
      }
    } catch (error) {
      toast.error('There has been an error fetching the grid data. Please try again or contact support.');
    } finally {
      setShowLoading(false);
    }
  }

  const handleCancel = () => {
    setProductDetails([]);
    setBlockedSeasons([]);
    setStyleInput('');
    setFormObj({ style: '', season: null, supplier: null, subsupplier: null });
    setChemicalTestFiles([{ file: null, result: null }]);
    setPhysicalTestFiles([]);
    setInspectionFiles([]);
    setShowCad(false);
  }

  const handleValidation = (editMode = false) => {
    const missingFields = [];
    const incompleteFields = [];

    if (!editMode) {
      if (!formObj.style) missingFields.push('Style');
      if (!formObj.season) missingFields.push('Season');
      if (!formObj.supplier) missingFields.push('Supplier');
      if (!formObj.subsupplier && listagemSubsupplier.length !== 0) missingFields.push('Subsupplier');
    }

    const fileArrays = [
      { name: 'Chemical test files', arr: chemicalTestFiles },
      { name: 'Physical test files', arr: physicalTestFiles },
      { name: 'Inspection Files', arr: inspectionFiles },
    ];

    for (const { name, arr } of fileArrays) {
      for (const [index, obj] of arr.entries()) {
        if (!obj.file || obj.result === null) incompleteFields.push(`${name} - Row ${index + 1}`);
      }
    }

    if (missingFields.length > 0 || incompleteFields.length > 0) {
      toast.error(
        <>
          {missingFields.length > 0 &&
            <>
              <div>The following fields are required:</div>
              <div>{missingFields.join(', ')}</div>
            </>
          }
          {incompleteFields.length > 0 &&
            <>
              <div>The following fields are incomplete:</div>
              {incompleteFields.map((str, index) => <div key={`errm_${index}`}>{str}</div>)}
            </>
          }
        </>
      );
      return false;
    }
    return true;
  }

  const appendFiles = (formData, key, filesArray) => {
    filesArray.forEach((item, index) => {
      formData.append(`${key}[${index}][file]`, item.file);
      formData.append(`${key}[${index}][result]`, item.result.value);
    });
  }

  const handleInput = async () => {
    try {
      setShowLoading(true);
      if (!handleValidation()) return;

      const styleInfo = {
        style: formObj.style,
        season: formObj.season.value,
        id_supplier: formObj.supplier.value,
        id_subsupplier: formObj.subsupplier ? formObj.subsupplier.value : null
      }

      const formData = new FormData();
      formData.append("styleInfo", JSON.stringify(styleInfo));
      appendFiles(formData, "chemicalTest", chemicalTestFiles);
      appendFiles(formData, "physicalTest", physicalTestFiles);
      appendFiles(formData, "inspection", inspectionFiles);

      await api.post('/FGReportsFarmGlobal/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      toast.success('Files successfully uploaded.');

      handleCancel();
    } catch (e) {
      toast.error('There has been an error uploading the files. Please try again or contact support.');
    } finally {
      setShowLoading(false);
    }
  }

  const handleInputAdditionalFiles = async () => {
    try {
      setShowLoading(true);
      if (!handleValidation(true)) return;

      const styleInfo = {
        id_report: selectedItem.id_report,
        style: selectedItem.style,
        season: selectedItem.season
      }

      const formData = new FormData();
      formData.append("styleInfo", JSON.stringify(styleInfo));
      appendFiles(formData, "chemicalTest", chemicalTestFiles);
      appendFiles(formData, "physicalTest", physicalTestFiles);
      appendFiles(formData, "inspection", inspectionFiles);

      const res = await api.post('/FGReportsFarmGlobal/upload-additional-files', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      toast.success('Files successfully uploaded.');

      setSelectedDocs(res.data);
      setChemicalTestFiles([]);
      setPhysicalTestFiles([]);
      setInspectionFiles([]);
    } catch (e) {
      toast.error('There has been an error uploading the files. Please try again or contact support.');
    } finally {
      setShowLoading(false);
    }
  }

  const handleSearchProduct = async (styleValue) => {
    setShowLoading(true);
    try {
      const res = await api.get('/FGReportsFarmGlobal/reports-product-info', { params: { produto: styleValue } });
      if (res.data.length === 0) {
        toast.error('No style was found with that value.');
        setProductDetails([]);
        return;
      }
      setProductDetails(res.data.styleInfo);
      setBlockedSeasons(res.data.blockedSeasons);
      setFormObj({ ...formObj, style: styleValue, season: null });
    } catch (e) {
      toast.error(`There has been an error searching for the style's information. Please try again or contact support.`);
      setProductDetails([]);
    } finally {
      setShowLoading(false);
    }
  }

  const handleSetSupplier = (opt) => {
    if (!opt) {
      setListagemSubsupplier([]);
      return setFormObj({ ...formObj, supplier: null, subsupplier: null });
    }

    const arr = [...listagem.subsupplier];
    const filteredArr = arr.filter(item => item.id_fornecedor === opt.value);
    setFormObj({ ...formObj, supplier: opt, subsupplier: null });
    setListagemSubsupplier([...filteredArr]);
  }

  const handleOpenDocsModal = async (params) => {
    try {
      setShowLoading(true);
      const res = await api.get('FGReportsFarmGlobal/reports-fg-fetch-files', { params });
      setSelectedItem(params);
      setSelectedDocs(res.data);
      setChemicalTestFiles([])
      setShowDocsModal(true);
    } catch (e) {
      toast.error(`Sorry, we were unable to fetch the selected reference's documents. Please try again or contact support.`)
    } finally {
      setShowLoading(false);
    }
  }

  const handleCloseDocsModal = () => {
    setShowDocsModal(false);
    setSelectedItem({});
    setSelectedDocs({});
    setChemicalTestFiles([{ file: null, result: null }]);
    setPhysicalTestFiles([]);
    setInspectionFiles([]);
  }

  const handleDataExport = async (seasonObj) => {
    try {
      const res = await api.get('FGReportsFarmGlobal/get-links-xlsx', {
        params: { season: seasonObj.value },
        responseType: 'blob'
      });

      const blob = res.data;
      const text = await blob.text();
      try {
        const json = JSON.parse(text);
        if (json.empty) {
          toast.warn(`Sorry, no entries with the specified season were found.`);
          return;
        }
      } catch (e) {}

      const url = URL.createObjectURL(res.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `farm-global-season-${seasonObj.value}-links.xlsx`;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    } catch (e) {
      toast.error(`Sorry, we were unable to fetch the required file. Please try again or contact support.`);
    }
  }

  const removeFile = async (item) => {
    try {
      setShowLoading(true);
      const params = {
        id_report: item.id_report,
        id_arquivo: item.id_arquivo,
        nome_arquivo: item.nome_arquivo,
        id_tipo_arquivo: item.id_tipo_arquivo,
        aprovado: item.aprovado,
        usuario: userLogin
      }
      const res = await api.post('FGReportsFarmGlobal/reports-fg-remove-files', params);
      setSelectedDocs(res.data);
    } catch (e) {
      toast.error(`Sorry, we were unable to remove the file. Please try again or contact support.`);
    } finally {
      setShowLoading(false);
    }
  }

  const removeReport = async () => {
    try {
      setShowLoading(true);
      const params = {
        id_report: selectedItem.id_report,
        usuario: userLogin
      }
      await api.post('FGReportsFarmGlobal/reports-fg-remove-report', params);
      toast.success('Report successfully removed.');
      setShowConfirmDeleteModal(false);
      setMainData(prev => prev.filter(item => item.id_report !== params.id_report));
      handleCloseDocsModal();
    } catch (e) {
      toast.error(`Sorry, we were unable to remove the report. Please try again or contact support.`);
    } finally {
      setShowLoading(false);
    }
  }

  const handeOpenExportModal = async () => {
    try {
      setShowLoading(true);
      const res = await api.get('FGReportsFarmGlobal/reports-fg-get-export-list');
      setExportOptions(res.data);
      setShowExportModal(true);
    } catch (e) {
      toast.error(`Sorry, we were unable to fetch the export options. Please try again or contact support.`);
    } finally {
      setShowLoading(false);
    }
  }

  const handleCloseExportModal = () => {
    setExportOptions([]);
    setShowExportModal(false);
  }

  return (
    <FGReportsFarmGlobalContext.Provider
      value={{
        userLogin,
        mainData,
        setMainData,
        handleSearch,
        setShowLoading,
        listagem,
        setListagem,
        filterObj,
        setFilterObj,
        handleSetFilterObj,
        handleShowCad,
        showCad,
        setShowCad,
        handleShowPDF,
        handleCancel,
        chemicalTestFiles,
        setChemicalTestFiles,
        physicalTestFiles,
        setPhysicalTestFiles,
        inspectionFiles,
        setInspectionFiles,
        handleInput,
        handleSearchProduct,
        productDetails,
        setProductDetails,
        formObj,
        setFormObj,
        styleInput,
        setStyleInput,
        handleSetSupplier,
        listagemSubsupplier, setListagemSubsupplier,
        showDocsModal, setShowDocsModal,
        selectedDocs, setSelectedDocs,
        handleOpenDocsModal, selectedItem, selectedDocs, handleCloseDocsModal, handleInputAdditionalFiles,
        blockedSeasons, removeFile, removeReport,
        showConfirmDeleteModal, setShowConfirmDeleteModal,
        showExportModal, exportOptions, handeOpenExportModal, handleCloseExportModal, handleDataExport
      }}
    >
      {<ReportsFarmGlobalMain />}
    </FGReportsFarmGlobalContext.Provider>
  );
}
