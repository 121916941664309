import { Modal, Button } from "react-bootstrap";
import { useFGReportsFarmGlobal } from "./ReportsFarmGlobalContext";
import styles from './ReportsFarmGlobal.module.scss';

const ModalReportRemoval = () => {
  const { showConfirmDeleteModal, setShowConfirmDeleteModal, removeReport } = useFGReportsFarmGlobal();

  return (
    <Modal
      show={showConfirmDeleteModal}
      onHide={() => setShowConfirmDeleteModal(false)}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
      backdropClassName={styles.backdrop_z_override}
      style={{ backgroundColor: '#F23C3C10', border: '1px solid #F23C3C' }}
    >
      <Modal.Header closeButton style={{ backgroundColor: '#F23C3C50', borderColor: '#F23C3C' }}>
        <Modal.Title>Report Removal Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#F23C3C50', borderColor: '#F23C3C' }}>
        <span style={{ fontWeight: '500' }}>Please confirm you wish to remove the report and all documents attached to it.</span>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: '#F23C3C50', borderColor: '#F23C3C' }}>
        <Button
          variant={'secondary'}
          style={{ width: '40%' }}
          onClick={() => setShowConfirmDeleteModal(false)}
        >
          Back
        </Button>
        <Button
          variant={'danger'}
          style={{ width: '40%', marginLeft: 'auto' }}
          onClick={() => removeReport()}
        >
          Confirm - Remove Report
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalReportRemoval;
