import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import styles from '../../../../styles/Corte/ControleEnfesto/DistribuicaoDemanda.module.scss';
import { useDistribuicaoDemanda } from '../../../../contexts/Corte/ControleEnfesto/DistribuicaoDemandaContext'; 

const ModalAlteraFolha = () => {

    const { updateFolhas, handleFolhasAlteradas, folhasAlteradas, gradeSelecionada, handleCloseAlteraFolha, showAlteraFolha, folhasAlocadas } = useDistribuicaoDemanda();

    return (
        <>
            <Modal
                show={showAlteraFolha}
                onHide={handleCloseAlteraFolha}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Insira a nova quantidade de folhas:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className={styles.filterInputModal}>
                            <Form.Control
                                placeholder={gradeSelecionada.GRADE}
                                type='text'
                                value={folhasAlteradas || ''}
                                onChange={e => handleFolhasAlteradas(e)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-danger"
                        className={styles.modalBtn}
                        onClick={handleCloseAlteraFolha}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="outline-success"
                        className={styles.modalBtn}
                        onClick={updateFolhas}
                    >
                        Alterar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalAlteraFolha;
