import Select from 'react-select';
import { Button, Modal, Form, InputGroup, Alert } from 'react-bootstrap';
import { useFGReportsFarmGlobal } from "./ReportsFarmGlobalContext.js";
import styles from './ReportsFarmGlobal.module.scss';
import ModalArquivosFarmGlobal from "./fg_reports_file_component.jsx";
import { isNullish } from '../../../utils/misc_utils.jsx';

const ModalReportsFarmGlobal = () => {
  const {
    showCad,
    listagem,
    handleCancel,
    handleInput,
    handleSearchProduct,
    formObj,
    setFormObj,
    styleInput,
    setStyleInput,
    productDetails,
    handleSetSupplier,
    listagemSubsupplier,
    blockedSeasons
  } = useFGReportsFarmGlobal();

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#dee2e6' : '#dee2e6',
      boxShadow: state.isFocused ? '0 0 0 1px #dee2e6' : 'none',
      '&:hover': { borderColor: '#dee2e6' },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
    })
  }

  return (
    <>
      <Modal
        show={showCad}
        onHide={handleCancel}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Report</Modal.Title>
        </Modal.Header>

        <Modal.Body className={styles.modal_body}>
          <div className={styles.product_wrapper}>
            {productDetails.length === 0 && <div className={styles.pi_label}>Please, insert a style</div>}
            {productDetails.map(item => (
              <div className={styles.product_info} key={item.id_produto_informacao}>
                <div><span className={styles.pi_label}>Identificação Produto</span> <span className={styles.pi_value}>{item.id_produto_informacao}</span></div>
                <div><span className={styles.pi_label}>Produto</span> <span className={styles.pi_value}>{item.produto}</span></div>
                <div><span className={styles.pi_label}>Descrição Produto</span> <span className={styles.pi_value}>{item.descricao}</span></div>
              </div>
            ))}
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Group style={{ width: '50%' }}>
              <Form.Label style={{ marginBottom: '10px', fontWeight: '500' }}>Style <span style={{ color: 'red' }}>*</span></Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Style"
                  value={styleInput}
                  onChange={e => setStyleInput(e.target.value)}
                  style={{ height: '38px' }}
                  className={styles.custom_input_brd}
                />
                <Button
                  variant="primary"
                  onClick={() => handleSearchProduct(styleInput)}
                >
                  Search
                </Button>
              </InputGroup>
            </Form.Group>

            <Form.Group style={{ width: '50%' }}>
              <Form.Label style={{ marginBottom: '10px', fontWeight: '500' }}>Season</Form.Label>
              <Select
                options={listagem.season}
                value={formObj.season}
                onChange={e => setFormObj({ ...formObj, season: e })}
                placeholder="Select a season"
                isClearable={true}
                styles={customSelectStyles}
                isOptionDisabled={opt => blockedSeasons.includes(opt.value)}
                isDisabled={isNullish(formObj.style)}
              />
            </Form.Group>
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Group style={{ width: '50%' }}>
              <Form.Label style={{ marginBottom: '10px', fontWeight: '500' }}>Supplier</Form.Label>
              <Select
                options={listagem.supplier}
                value={formObj.supplier}
                onChange={e => handleSetSupplier(e)}
                placeholder="Select a supplier"
                isClearable={true}
                styles={customSelectStyles}
              />
            </Form.Group>

            <Form.Group style={{ width: '50%' }}>
              <Form.Label style={{ marginBottom: '10px', fontWeight: '500' }}>Subsupplier</Form.Label>
              <Select
                options={listagemSubsupplier}
                value={formObj.subsupplier}
                onChange={e => setFormObj({ ...formObj, subsupplier: e })}
                placeholder="Select a subsupplier"
                isClearable={true}
                isDisabled={!formObj.supplier || listagemSubsupplier.length === 0}
                styles={customSelectStyles}
              />
            </Form.Group>
          </div>

          {formObj.supplier && listagemSubsupplier.length === 0 && (
            <Alert variant="warning" style={{ margin: '0px' }}>
              Não há subsuppliers para o supplier determinado.
            </Alert>
          )}

          <div className={styles.separator} />

          <ModalArquivosFarmGlobal />

        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'center', gap: '30px' }}>
          <Button variant="secondary" style={{ width: '200px' }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" style={{ width: '200px' }} onClick={() => handleInput()}>
            Input
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalReportsFarmGlobal;
