import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { api } from '../../../services/api';

import Select from 'react-select';
import styles from '../../../styles/PA/Desfazer.module.scss';
import MainGridDesfazer from './components/gridDesfazer';
import ModalDesfazer from './components/modalDesfazer';
import { listaProcessos } from './desfazer_aux';
import CookiesService from '../../../services/cookies';
import { useMisc } from '../../../contexts/MiscContext';
const userAcessObj = CookiesService.getUserData()?.acessos || {};

function DesfazerMain() {
  const { setShowLoading } = useMisc();
  const [processoSelecionado, setProcessoSelecionado] = useState(null);
  const [processoFiltrado, setProcessoFiltrado] = useState({});
  const [objSelecionado, setObjSelecionado] = useState({});
  const [formObj, setFormObj] = useState({});
  const [mainData, setMainData] = useState([]);
  const [modalAtivo, setModalAtivo] = useState(null);
  const [listaMotivos, setListaMotivos] = useState([]);

  const handleBuscar = async () => {
    try {
      if (!processoSelecionado?.value) return toast.warn('Por favor Selecione um tipo de processo!');
      const params = {
        ...formObj,
        tipoProcesso: processoSelecionado.value
      }

      const res = await api.get(`PADesfazer/${processoSelecionado.endpointConsulta}`, { params })
      setProcessoFiltrado(processoSelecionado);
      setMainData(res.data);
    } catch (e) {
      toast.error('Ocorreu um erro durante a busca, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
    } finally {
      setShowLoading(false);
    }
  }

  const handleNumberDotMask = (field, value) => {
    if (value && value !== undefined) value = `${value.replace(/[^.a-zA-Z0-9]/g, '')}`;
    setFormObj({ ...formObj, [field]: value });
  }

  const checkIfIsDisabled = () => {
    if (
      !processoSelecionado
      || (!formObj.nfEntrada && !formObj.produto && !formObj.corProduto && !formObj.qtdeEntrada)
      || (processoSelecionado.value === 'analiseDuplicada' && (
        !formObj.nfEntrada || !formObj.produto || !formObj.corProduto || !formObj.qtdeEntrada
      ))
      || (processoSelecionado.value === 'numeroAnalise' && (
        !formObj.produto || !formObj.numeroAnalise
      ))
    ) return true;
    else return false;
  }

  const handleDisableOption = (opt) => {
    if (process.env.REACT_APP_IS_DEBUG_MODE) return false;
    if (userAcessObj[opt.controleAcesso] !== true) return true;
    return false;
  }

  useEffect(() => {
    (async () => {
      await api.get(`PADesfazer/listarMotivos`)
        .then(res => {
          setListaMotivos(res.data);
        }).catch(e => {
          toast.error('Ocorreu um erro durante a busca, por favor cheque sua conexão com a internet ou entre em contato com o suporte');
        });
    })();
  }, []);

  return (
    <>
      <div className="p-2">
        <h2 className="ms-3">Desfazer Processos - PA</h2>
      </div>

      <Form className={styles.form}>
        <Form.Group className={styles.formControl}>
          <Form.Label className={styles.formLabel}>Selecionar Processo</Form.Label>
          <Select
            className={styles.filterWidth}
            placeholder={'Selecionar'}
            closeMenuOnSelect={true}
            value={processoSelecionado}
            onChange={opt => {
              setProcessoSelecionado(opt);
              if (opt?.value !== 'dataEntrada') setFormObj({ ...formObj, dataEntrada: '' });
            }}
            options={listaProcessos}
            isClearable={true}
            isOptionDisabled={opt => handleDisableOption(opt)}
          />
        </Form.Group>

        <Form.Group className={styles.filterWidth} controlId='nfEntrada'>
          <Form.Label>NF Entrada
            <span style={{ display: processoSelecionado?.value === 'analiseDuplicada' ? 'initial' : 'none' }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={'Nota Fiscal de Entrada'}
            value={formObj.nfEntrada || ''}
            onChange={e => handleNumberDotMask('nfEntrada', e.target.value)}
          />
        </Form.Group>

        <Form.Group className={styles.filterWidth} controlId='produto'>
          <Form.Label>Produto
            <span style={{ display: processoSelecionado?.value === 'analiseDuplicada' ? 'initial' : 'none' }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={'Produto'}
            value={formObj.produto || ''}
            onChange={e => handleNumberDotMask('produto', e.target.value)}
          />
        </Form.Group>

        <Form.Group className={styles.filterWidth} controlId='corProduto'>
          <Form.Label>Cor Produto
            <span style={{ display: processoSelecionado?.value === 'analiseDuplicada' ? 'initial' : 'none' }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={'Cor do produto'}
            value={formObj.corProduto || ''}
            onChange={e => handleNumberDotMask('corProduto', e.target.value)}
          />
        </Form.Group>

        <Form.Group className={styles.filterWidth} controlId='qtdeEntrada'>
          <Form.Label>Quantidade de Entrada
            <span style={{ display: processoSelecionado?.value === 'analiseDuplicada' ? 'initial' : 'none' }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={'Quantidade de Entrada'}
            value={formObj.qtdeEntrada || ''}
            onChange={e => handleNumberDotMask('qtdeEntrada', e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className={styles.filterWidth}
          controlId='dataEntrada'
          style={{ display: processoSelecionado?.value === 'dataEntrada' ? 'block' : 'none' }}
        >
          <Form.Label>Data de Entrada</Form.Label>
          <Form.Control
            type="date"
            value={formObj.dataEntrada || ''}
            onChange={e => setFormObj({ ...formObj, dataEntrada: e.target.value })}
            placeholder={'Data de Entrada'}
            min={'2000-01-01'}
            max={'9999-01-01'}
          />
        </Form.Group>

        <Form.Group
          className={styles.filterWidth}
          controlId='ordemProducao'
          style={{ display: processoSelecionado?.value === 'analiseDuplicada' ? 'block' : 'none' }}
        >
          <Form.Label>Ordem Produção</Form.Label>
          <Form.Control
            type="text"
            placeholder={'Ordem Produção'}
            value={formObj.ordemProducao || ''}
            onChange={e => handleNumberDotMask('ordemProducao', e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className={styles.filterWidth}
          controlId='numeroAnalise'
          style={{ display: processoSelecionado?.value === 'numeroAnalise' ? 'block' : 'none' }}
        >
          <Form.Label>Número do Boleto</Form.Label>
          <Form.Control
            type="text"
            placeholder={'Número do Boleto'}
            value={formObj.numeroAnalise || ''}
            onChange={e => handleNumberDotMask('numeroAnalise', e.target.value)}
          />
        </Form.Group>

        <Button
          className={styles.filterWidth}
          variant="primary"
          type="button"
          onClick={handleBuscar}
          disabled={checkIfIsDisabled()}
        >
          Buscar
        </Button>
      </Form>

      <MainGridDesfazer
        mainData={mainData}
        processoFiltrado={processoFiltrado}
        setModalAtivo={setModalAtivo}
        setObjSelecionado={setObjSelecionado}
        toast={toast}
      ></MainGridDesfazer>

      <ModalDesfazer
        mainData={mainData}
        processoFiltrado={processoFiltrado}
        modalAtivo={modalAtivo}
        setModalAtivo={setModalAtivo}
        setObjSelecionado={setObjSelecionado}
        objSelecionado={objSelecionado}
        listaMotivos={listaMotivos}
        toast={toast}
        setMainData={setMainData}
      ></ModalDesfazer>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  )
}

export default DesfazerMain;
