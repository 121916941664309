import { createContext, useEffect, useContext, useState } from 'react';
import { api } from '../../../services/api';
import { toast } from 'react-toastify';
import CookiesService from '../../../services/cookies';
import { toDatetime, toUTCDDMMYYYY } from '../../../utils/dateUtils';
import { useMisc } from '../../MiscContext';

const DistribuicaoDemandaContext = createContext();
export const useDistribuicaoDemanda = () => useContext(DistribuicaoDemandaContext);

export const DistribuicaoDemandaProvider = ({ children }) => {

  const { setShowLoading } = useMisc();

  const checklistColumns = [
    { name: 'DATA', selector: row => toUTCDDMMYYYY(row.DataPriorizacao), atr: 'DataPriorizacao', sortable: true },
    { name: 'EQUIPE', selector: row => row.Equipe, atr: 'Equipe', sortable: true },
    { name: 'ORDEM SERVIÇO', selector: row => row.OrdemServico, atr: 'OrdemServico' },
    { name: 'PRODUTO', selector: row => row.Produto, atr: 'Produto' },
    { name: 'MATERIAL', selector: row => row.Material, atr: 'Material' },
    { name: 'DESCRIÇÃO MATERIAL', selector: row => row.Desc_Material, atr: 'Desc_Material' },
    { name: 'COR MATERIAL', selector: row => row.CorMaterial, atr: 'CorMaterial' },
    { name: 'DESCRIÇÃO COR', selector: row => row.Desc_Cor_Material, atr: 'Desc_Cor_Material' },
    { name: 'GRADE', selector: row => row.Grade, atr: 'Grade' },
    { name: '%', selector: row => row.percent_percent, atr: 'percent_percent' },
    { name: 'PRIORIZAÇÃO', selector: row => row.Priorizacao, atr: 'Priorizacao' },
    { name: 'TIPO CORTE', selector: row => row.TipoCorte, atr: 'TipoCorte', sortable: true },
    { name: 'QUANTIDADE TECIDO', selector: row => row.QTDE_TECIDO, atr: 'QTDE_TECIDO' },
    { name: 'QUANTIDADE PROCESSO', selector: row => row.QTD_PROCESSO, atr: 'QTD_PROCESSO' },
    { name: 'QUANTIDADE REENFESTO', selector: row => row.QTD_REENFESTO, atr: 'QTD_REENFESTO' },
    { name: 'COLEÇÃO', selector: row => row.COLECAO, atr: 'COLECAO' },
    { name: 'LANÇAMENTO', selector: row => row.LANCAMENTO, atr: 'LANCAMENTO' },
    { name: 'MODO CORTE', selector: row => row.ModoCorte, atr: 'ModoCorte' }
  ];

  const [loading, setLoading] = useState(false);

  const userLogin = CookiesService.getUserLogin();
  const dataProcesso = toDatetime(new Date());

  const [mainData, setMainData] = useState([])
  const [enfestData, setEnfestData] = useState([])
  const [gradeData, setGradeData] = useState([])
  const [imagemData, setImagemData] = useState('');

  const [filtro, setFiltro] = useState('');

  const [linhaSelecionada, setLinhaSelecionada] = useState({});
  const [gradeSelecionada, setGradeSelecionada] = useState({});
  const [folhasAlteradas, setFolhasAlteradas] = useState('');
  const [maxFolhasAlteradas, setMaxFolhasAlteradas] = useState('');
  const [enfestSelecionadoUm, setEnfestSelecionadoUm] = useState({});
  const [enfestSelecionadoDois, setEnfestSelecionadoDois] = useState({});
  const [folhasAlocadas, setFolhasAlocadas] = useState('');

  const [showGrade, setShowGrade] = useState(false);
  const [showImagem, setShowImagem] = useState(false);
  const [showAlteraFolha, setShowAlteraFolha] = useState(false);
  const [showEnfest, setShowEnfest] = useState(false);
  const [showConfirmacao, setShowConfirmacao] = useState(false);

  const fetchMainData = async () => {
    setLoading(true);
    try {
      const res = await api.get('Corte/ControleEnfesto/DistribuicaoDemanda/get-rows');
      setMainData(res.data.resPrincipal);
      setEnfestData(res.data.resEnfest);
    } catch (e) {
        toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
        setLoading(false);
    }
  };

  const fetchGradeData = async (row) => {
    setShowLoading(true);
    try {
      const res = await api.get('Corte/ControleEnfesto/DistribuicaoDemanda/get-grade', { params: row });
      setGradeData(res.data.resGrade)
      setImagemData(res.data.imageUrl)
    } catch (e) {
        toast.error('Ocorreu um erro ao carregar a grade, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
    } finally {
      setShowLoading(false);
      setShowGrade(true)
    }
  };

  const insertEnfest = async () => {

    const params = {
      os: linhaSelecionada.OrdemServico,
      material: linhaSelecionada.Material,
      cor: linhaSelecionada.CorMaterial,
      grade: gradeSelecionada.GRADE,
      enfestUm: enfestSelecionadoUm.IdOperadores,
      enfestDois: enfestSelecionadoDois.IdOperadores,
      qtdeFolhas: folhasAlocadas,
      usuario: userLogin, 
      dataProcesso: dataProcesso
    } 

    try {
      setShowLoading(true);
      setShowConfirmacao(false);
      const res = await api.post('Corte/ControleEnfesto/DistribuicaoDemanda/insert-enfest', { params });
      if (res.status === 200) {
        toast.success('Dados inseridos com sucesso!');
      } else {
          toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
      }
    } catch (e) {
        toast.error('Ocorreu um erro ao inserir os dados na tabela, por favor cheque sua internet e/ou entre em contato com o suporte');
        console.error(`Erro na requisição - ${e}`);
        setShowConfirmacao(true);
    } finally {
      handleFinalizaGrade()
      fetchMainData()
      setShowLoading(false);
    }
  }

  const updateFolhas = async () => {
    if (!folhasAlteradas) {
      toast.warning('É necessário inserir nova quantidade de folhas.'); 
      return
    } else if(folhasAlteradas < maxFolhasAlteradas) {
      toast.warning(`Não é possível alterar para menos do que ${maxFolhasAlteradas} folhas.`); 
      return
    } else {
      setShowLoading(true);
      setShowAlteraFolha(false)
      const params = {
        IdLancamentoCad: linhaSelecionada.IdLancamentoCad,
        qtdeFolhas: folhasAlteradas,
        grade: gradeSelecionada.GRADE
      } 

      try {
        const res = await api.post('Corte/ControleEnfesto/DistribuicaoDemanda/update-folhas', { params });
        if (res.status === 200) {
          toast.success('Dados atualizados com sucesso!');
        } else {
            toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }
      } catch (e) {
          toast.error('Ocorreu um erro ao inserir os dados na tabela, por favor cheque sua internet e/ou entre em contato com o suporte');
          console.error(`Erro na requisição - ${e}`);
          setShowAlteraFolha(true)
      } finally {
        fetchGradeData(linhaSelecionada)
        setFolhasAlteradas('')
      }
    }
  }

  const handleShowGrade = (row) => {
    fetchGradeData(row)
    setLinhaSelecionada(row)
  }

  const handleCloseGrade = () => {
    setLinhaSelecionada({})
    setGradeData([])
    setImagemData('')
    setShowGrade(false)
  }

  const handleShowImagem = (row) => {
    setShowGrade(false)
    setShowImagem(true)
  }

  const handleCloseImagem = () => {
    setShowImagem(false)
    setShowGrade(true)
  }

  const handleShowAlteraFolha = (grade) => {
    setGradeSelecionada(grade)
    setShowGrade(false)
    setShowAlteraFolha(true)
    setMaxFolhasAlteradas(grade.QtdeFolhasAlocada)
  }
  
  const handleCloseAlteraFolha = () => {
    setGradeSelecionada({})
    setFolhasAlteradas('')
    setMaxFolhasAlteradas('')
    setShowAlteraFolha(false)
    setShowGrade(true)
  }
  
  const handleShowEnfest = (grade) => {
    setGradeSelecionada(grade)
    setShowEnfest(true)
    setShowGrade(false)
  }

  const handleCloseEnfest = () => {
    setEnfestSelecionadoUm({})
    setEnfestSelecionadoDois({})
    setFolhasAlocadas('')
    setGradeSelecionada({})
    setShowEnfest(false)
    setShowGrade(true)
  }

  const handleShowConfirmacao = () => {
    if (Object.keys(enfestSelecionadoUm).length === 0 || Object.keys(enfestSelecionadoDois).length === 0){
      toast.warning('É necessário escolher os enfestadores.'); 
      return
    } else if (enfestSelecionadoUm === enfestSelecionadoDois){
      toast.warning('Os enfestadores não podem ser o mesmo.'); 
      return
    } else if (!folhasAlocadas) {
      toast.warning('É necessário inserir as folhas alocadas.'); 
      return
    } else if (folhasAlocadas >  (!gradeSelecionada.QtdeFolhasEncarregado ? gradeSelecionada.QtdeFolhas - gradeSelecionada.QtdeFolhasAlocada : gradeSelecionada.QtdeFolhasEncarregado - gradeSelecionada.QtdeFolhasAlocada)) {
      toast.warning('Folhas alocadas não pode ser maior do que a quantidade de folhas restantes.'); 
      return
    } else {
      setShowEnfest(false)
      setShowConfirmacao(true)
    } 
  }

  const handleCloseConfirmacao = () => {
    setShowConfirmacao(false)
    setShowEnfest(true)
  }

  const handleFolhasAlteradas = (e) => {
    const regex = /^\d*$/
    if (!regex.test(e.target.value)){
        return
    }
    const qtde = parseInt(e.target.value)
    setFolhasAlteradas(qtde)
  }

  const handleFolhasAlocadas = (e) => {
    const regex = /^\d*$/
    if (!regex.test(e.target.value)){
        return
    }
    const qtde = parseInt(e.target.value)
    setFolhasAlocadas(qtde)
  }

  const handleFinalizaGrade = () => {
    fetchGradeData(linhaSelecionada)
    setEnfestSelecionadoUm({})
    setEnfestSelecionadoDois({})
    setFolhasAlocadas('')
  }

  useEffect(() => {
      fetchMainData();
  }, []);

  return (
    <DistribuicaoDemandaContext.Provider
      value={{ maxFolhasAlteradas, filtro, setFiltro, imagemData, handleShowImagem, handleCloseImagem, showImagem, updateFolhas, folhasAlteradas, handleFolhasAlteradas, showAlteraFolha, handleShowAlteraFolha, handleCloseAlteraFolha, folhasAlocadas, handleFolhasAlocadas, gradeSelecionada, showEnfest, handleShowEnfest, handleCloseEnfest, gradeData, enfestSelecionadoUm, enfestSelecionadoDois, setEnfestSelecionadoUm, setEnfestSelecionadoDois, enfestData, insertEnfest, linhaSelecionada, showConfirmacao, handleShowConfirmacao, handleCloseConfirmacao, handleShowGrade, handleCloseGrade, showGrade, checklistColumns, loading, setLoading, mainData, setMainData }}
    >
      {children}
    </DistribuicaoDemandaContext.Provider>
  )
}