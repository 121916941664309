import React from "react";
import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import "../styles/NavbarStyles.css";
import AccessControl from "./AccessControl";
import CookiesService from "../services/cookies";
import { useAuth } from "../contexts/AuthContext";
import { useMisc } from "../contexts/MiscContext";

export default () => {
  const { isAuthenticated, clearSession } = useAuth();
  const { setShowControleAcessoModal } = useMisc();

  return (
    isAuthenticated &&
    <Navbar className="row justify-content-start px-2" bg="light" expand="lg" style={{ borderBottom: '1px solid #00000030' }}>
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            className="img-responsive p-2 "
            src="img/soma_trans.png"
            alt="Grupo Soma"
            width="100"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <AccessControl areas={['Qualidade']}>
            <Nav className="justify-content-start w-100">
              <Nav className="ml-auto">
                <AccessControl areas={['Qualidade - MP']}>
                  <NavDropdown title="QUALIDADE MP" id="basic-nav-dropdown">
                    <AccessControl areas={['Processos ADM - MP']}>
                      <NavDropdown drop='end' title="C.Q PROCESSO ADM" id="basic-nav-dropdown">
                        <AccessControl areas={['Catalogo de Amostras - MP']}>
                          <NavDropdown title="CATALOGO DE AMOSTRAS" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/salaamostras">SALA DE AMOSTRAS</NavDropdown.Item>
                            <NavDropdown.Item href="/cadastroamostras">CADASTRO AMOSTRAS</NavDropdown.Item>
                            <NavDropdown title="ADM" id="basic-nav-dropdown">
                              <NavDropdown.Item href="/admimpressaoetiquetas">IMPRESSÃO DE ETIQUETAS</NavDropdown.Item>
                              <NavDropdown.Item href="/admenvioretornoamostras">ENVIO / RETORNO AMOSTRAS</NavDropdown.Item>
                              <NavDropdown.Item href="/admvigenciaexclusao">VIGÊNCIA / EXCLUSÃO</NavDropdown.Item>
                              <NavDropdown.Item href="/admconsultarcatalogo">CONSULTAR CATÁLOGO</NavDropdown.Item>
                            </NavDropdown>
                          </NavDropdown>
                        </AccessControl>
                        <AccessControl areas={['Adm - MP']}>
                          <NavDropdown.Item href="/adm">ADM</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Aprovacao Estilo - MP']}>
                          <NavDropdown.Item href="/aprovacaoestilo">APROVAÇÃO ESTILO</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Adm MP - MP']}>
                          <NavDropdown title="ADM - MP" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/alterarrevisao">ALTERAR REVISÃO</NavDropdown.Item>
                            <NavDropdown.Item href="/alterarlaboratorio">ALTERAR LABORATÓRIO</NavDropdown.Item>
                          </NavDropdown>
                          {/* <NavDropdown.Item href="/admmp">ADM MP</NavDropdown.Item> */}
                        </AccessControl>
                        <AccessControl areas={['Desconto - MP']}>
                          <NavDropdown title="DESCONTO" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/desconto">ACOMPANHAMENTO DE DESCONTO</NavDropdown.Item>
                            <NavDropdown.Item href="/editarpendenciar">EDITAR/PENDENCIAR</NavDropdown.Item>
                          </NavDropdown>
                        </AccessControl>
                      </NavDropdown>
                    </AccessControl>
                    <AccessControl areas={['Processo Materia Prima - MP']}>
                      <NavDropdown drop='end' title="C.Q PROCESSO MATERIA PRIMA" id="basic-nav-dropdown">
                        <AccessControl areas={['Recebimento - MP']}>
                          <NavDropdown.Item href="/recebimentomp">RECEBIMENTO MP</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Processos - MP']}>
                          <NavDropdown.Item href="/processos">PROCESSOS</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Revisao - MP']}>
                          <NavDropdown.Item href="/revisao">REVISÃO</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Laboratorio - MP']}>
                          <NavDropdown drop='end' className="ms-2" title="LABORATÓRIO" id="laboratorio-dropdown">
                            <NavDropdown.Item href="/laboratorio/monitorar">MONITORAR</NavDropdown.Item>
                            <NavDropdown.Item href="/laboratorio/agendar">AGENDAR</NavDropdown.Item>
                          </NavDropdown>
                        </AccessControl>
                        <AccessControl areas={['Envio Plotter - MP']}>
                          <NavDropdown.Item href="/envioplotter">ENVIO PLOTTER</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Analises CemPorcento Retorno - MP']}>
                          <NavDropdown drop='end' className="ms-2" title="ANÁLISES 100%/RETORNO" id="cemporcento-dropdown">
                            <NavDropdown.Item href="/analises/agendamentorevisao">AGENDAMENTO REVISÃO 100%/RETORNO</NavDropdown.Item>
                            <NavDropdown.Item href="/analises/revisaolaboratorio">REVISÃO/LABORATÓRIO</NavDropdown.Item>
                            <NavDropdown.Item href="/analises/revisaocemporcentoretorno">REVISÃO / 100% - RETORNO</NavDropdown.Item>
                            <NavDropdown.Item href="/analises/laboratoriocemporcentoretorno">LABORATÓRIO / 100% - RETORNO</NavDropdown.Item>
                          </NavDropdown>
                        </AccessControl>
                        <AccessControl areas={['Medicao Rapport - MP']}>
                          <NavDropdown drop='end' className="ms-2" title="RAPPORT" id="rapport-dropdown">
                            <NavDropdown.Item href="/rapport/medicaorapport">MEDIÇÃO DE RAPPORT</NavDropdown.Item>
                            <NavDropdown.Item href="/rapport/editarexcluir">EDITAR/EXCLUIR RAPPORT</NavDropdown.Item>
                            <NavDropdown.Item href="/rapport/rapportreprovado">RAPPORT REPROVADO</NavDropdown.Item>
                            <NavDropdown.Item href="/rapport/analiserapportreprovadocad">ANÁLISE RAPPORT REPROVADO - CAD</NavDropdown.Item>
                            <AccessControl areas={['Envio CAD - MP']}>
                              <NavDropdown.Item href="/rapport/rapportenviocad">RAPPORT ENVIO - CAD</NavDropdown.Item>
                            </AccessControl>
                            <NavDropdown.Item href="/rapport/rapportanaliseplanner">RAPPORT ANÁLISE ESTILO</NavDropdown.Item>
                          </NavDropdown>
                        </AccessControl>
                        <AccessControl areas={['Chamados - MP']}>
                          <NavDropdown drop='end' className="ms-2" title="CHAMADOS" id="chamados-dropdown">
                            <NavDropdown.Item href="/chamados/reclassificacaochamados">RECLASSIFICAÇÃO DE CHAMADOS</NavDropdown.Item>
                          </NavDropdown>
                        </AccessControl>
                        {/* <NavDropdown.Item href="/mp/triangulacao">TRIANGULAÇÃO</NavDropdown.Item> */}
                        {/* <AccessControl areas={['Cancelar Saida Perda - MP']}>
                          <NavDropdown.Item href="/cancelarsaidaperda" disabled>CANCELAR SAÍDA PERDA</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Teste Base - MP']}>
                          <NavDropdown.Item href="/testebase" disabled>TESTE BASE</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Teste Base Sourcing - MP']}>
                          <NavDropdown.Item href="/testebasesourcing" disabled>TESTE BASE SOURCING</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Cadastro de Teste - MP']}>
                          <NavDropdown.Item href="/cadastroteste" disabled>CADASTRO DE TESTE</NavDropdown.Item>
                        </AccessControl> */}
                      </NavDropdown>
                    </AccessControl>
                  </NavDropdown>
                </AccessControl>
                <AccessControl areas={['Qualidade - PA']}>
                  <NavDropdown title="QUALIDADE PA" id="basic-nav-dropdown">
                    <AccessControl areas={['Catalogo Peca Pilot - PA']}>
                      <NavDropdown title="PEÇA PILOTO" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/PA/salaamostras">SALA DE AMOSTRAS</NavDropdown.Item>
                        <NavDropdown.Item href="/PA/cadastroamostras">CADASTRO AMOSTRAS</NavDropdown.Item>
                        <NavDropdown.Item href="/PA/admimpressaoetiquetas">IMPRESSÃO DE ETIQUETAS</NavDropdown.Item>
                        <NavDropdown.Item href="/PA/consultaexclusao">CONSULTA E EXCLUSÃO</NavDropdown.Item>
                      </NavDropdown>
                    </AccessControl>
                    <AccessControl areas={['Monitor Revisao - PA', 'Adm - PA', 'Monitor Checklist - PA', 'Controle Aprovacao - PA', 'Revisao Externa - PA']}>
                      <NavDropdown title="ENTRADA FILIAL REVISÃO" id="basic-nav-dropdown">
                        <AccessControl areas={['Monitor Revisao - PA']}>
                          <NavDropdown.Item href="/PA/monitorrevisao" style={{ padding: '8px' }}>MONITOR REVISÃO</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Monitor Revisao - PA']}>
                          <NavDropdown.Item href="/PA/revisao" style={{ padding: '8px' }}>REVISÃO (NOVO)</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Adm - PA']}>
                          <NavDropdown title="CHECKLIST" id="basic-nav-dropdown" style={{ padding: '0px' }}>
                            <AccessControl areas={['Monitor Checklist - PA']}>
                              <NavDropdown.Item href="/PA/monitorchecklist">MONITOR CHECKLIST</NavDropdown.Item>
                            </AccessControl>
                            <AccessControl areas={['Controle Aprovacao - PA']}>
                              <NavDropdown.Item href="/PA/controleaprovacao">CONTROLE APROVAÇÃO</NavDropdown.Item>
                            </AccessControl>
                            {/* <AccessControl areas={['Monitor Checklist - PA']}>
                              <NavDropdown.Item href="/PA/checklist">CHECKLIST (NOVO)</NavDropdown.Item>
                            </AccessControl> */}
                          </NavDropdown>
                        </AccessControl>
                        <AccessControl areas={['Adm - PA']}>
                          <NavDropdown title="ADM" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/PA/gerar-laudo">GERAR LAUDO CHECKLIST</NavDropdown.Item>
                            {/* <NavDropdown.Item href="/PA/ADM/controleenvioaprovacao">CONTROLE ENVIO APROVAÇÃO</NavDropdown.Item> */}
                            {/* <NavDropdown.Item href="/PA/ADM/direcionamentopecas">DIRECIONAMENTO PEÇAS</NavDropdown.Item> */}
                            <NavDropdown.Item href="/PA/ADM/orientacaoconserto">ORIENTAÇÃO CONSERTO</NavDropdown.Item>
                            <NavDropdown.Item href="/PA/ADM/medidas">MEDIDAS</NavDropdown.Item>
                            <NavDropdown.Item href="/PA/ADM/devolucaopa">DEVOLUÇÃO PA</NavDropdown.Item>
                            <NavDropdown.Item href="/PA/ADM/alteracaorevisaochecklist">ALTERAR REVISÃO/CHECKLIST</NavDropdown.Item>
                            <NavDropdown.Item href="/PA/ADM/entradafiscal">ENTRADA FISCAL</NavDropdown.Item>
                          </NavDropdown>
                        </AccessControl>
                        <AccessControl areas={['Revisao Externa - PA']}>
                          <NavDropdown.Item href="/PA/revisaoexterna" style={{ padding: '8px' }}>REVISÃO EXTERNA</NavDropdown.Item>
                        </AccessControl>
                        {/* <NavDropdown.Item href="/revisaoimportado" disabled>REVISÃO IMPORTADO</NavDropdown.Item> */}
                      </NavDropdown>
                    </AccessControl>
                    <AccessControl areas={['Formulario Analise - PA']}>
                      <NavDropdown title="ANÁLISE LOJA" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/PA/formularioanalise">FORMULÁRIO ANÁLISE</NavDropdown.Item>
                      </NavDropdown>
                    </AccessControl>
                    <NavDropdown title="DEVOLUÇÃO" id="basic-nav-dropdown">
                      <AccessControl areas={['Agend Dev - PA']}>
                        <NavDropdown.Item href="/PA/agendamentodevolucao">AGENDAMENTO DEVOLUÇÃO</NavDropdown.Item>
                      </AccessControl>
                      <AccessControl areas={['Devolucao - PA']}>
                        <NavDropdown.Item href="/PA/controledevolucao">CONTROLE DEVOLUÇÃO</NavDropdown.Item>
                      </AccessControl>
                    </NavDropdown>

                    <AccessControl areas={['Monitor Revisao - PA']}>
                      <NavDropdown.Item style={{ padding: '8px' }} title="REVERSA E-COMMERCE PA" id="basic-nav-dropdown" href="/PA/reversa">
                        REVERSA E-COMMERCE PA
                      </NavDropdown.Item>
                    </AccessControl>

                    <AccessControl areas={['Monitor Revisao - PA']}>
                      <NavDropdown.Item style={{ padding: '8px' }} title="SEGUNDA QUALIDADE NATI VOZZA" id="basic-nav-dropdown" href="/PA/revisao-segunda-qualidade-nv">
                        SEGUNDA QUALIDADE NATI VOZZA
                      </NavDropdown.Item>
                    </AccessControl>

                    <AccessControl acesso={'pa_desfazer'}>
                      <NavDropdown.Item style={{ padding: '8px' }} title="DESFAZER" id="basic-nav-dropdown" href="/PA/desfazer">
                        DESFAZER
                      </NavDropdown.Item>
                    </AccessControl>
                  </NavDropdown>
                </AccessControl>

                <AccessControl areas={['Corte']} acesso={'Corte'}>
                  <NavDropdown title="CORTE" id="basic-nav-dropdown">
                    <AccessControl areas={['Reclassificacao Tipo Corte']}>
                      <NavDropdown.Item href="/corte/reclassificacaotipocorte">RECLASSIFICAÇÃO TIPO CORTE</NavDropdown.Item>
                    </AccessControl>
                    <AccessControl areas={['Planejamento Diario Corte']}>
                      <NavDropdown.Item href="/corte/planejamentodiariocorte">PLANEJAMENTO DIÁRIO</NavDropdown.Item>
                    </AccessControl>
                    <AccessControl areas={['Pesagem']}>
                      <NavDropdown.Item href="/pickingmateriais">PESAGEM</NavDropdown.Item>
                    </AccessControl>

                    <AccessControl areas={['Enfesto']} acesso={'Corte - Controle de Enfesto'}>
                      <NavDropdown drop='end' className="ms-2" title="CONTROLE DE ENFESTO" id="enfesto-dropdown">
                        <AccessControl areas={['Enfesto']} acesso={'Corte - Controle de Enfesto - Seleção dos Riscos'}>
                          <NavDropdown.Item href="/corte/controleenfesto/selecaoriscos">SELEÇÃO DOS RISCOS</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Enfesto']} acesso={'Corte - Controle de Enfesto - Lançamento CAD'}>
                          <NavDropdown.Item href="/corte/controleenfesto/lancamentocad">LANÇAMENTO CAD</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Enfesto']} acesso={'Corte - Controle de Enfesto - Priorização de OPs'}>
                          <NavDropdown.Item href="/corte/controleenfesto/priorizacaoops">PRIORIZAÇÃO DE OPS</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Enfesto']} acesso={'Corte - Controle de Enfesto - Distribuição de Demanda'}>
                          <NavDropdown.Item href="/corte/controleenfesto/distribuicaodemanda">DISTRIBUIÇÃO DE DEMANDA</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl acesso={'Corte - Controle de Enfesto - Enfesto'}>
                          <NavDropdown.Item href="/corte/controleenfesto/enfesto">ENFESTO</NavDropdown.Item>
                        </AccessControl>
                        <AccessControl areas={['Enfesto']} acesso={'Corte - Controle de Enfesto - Acompanhamento dos Enfestos'}>
                          <NavDropdown.Item href="/corte/controleenfesto/acompanhamentoenfesto">ACOMPANHAMENTO DOS ENFESTOS</NavDropdown.Item>
                        </AccessControl>
                      </NavDropdown>
                    </AccessControl>

                  </NavDropdown>
                </AccessControl>

                <AccessControl areas={['Qualidade - Farm Global', 'Qualidade - MP']} acesso={'Qualidade - Farm Global'}>
                  <NavDropdown title="FARM GLOBAL" id="basic-nav-dropdown">
                    <AccessControl areas={['Qualidade - Farm Global', 'Qualidade - MP']} acesso={'Qualidade - Farm Global'}>
                      <NavDropdown.Item href="/FG/materiaiscomposicao">CADASTRO DE BASE</NavDropdown.Item>
                      <NavDropdown drop='end' className="ms-2" title="CQ PROCESSO TESTE DE BASE" id="testebase-dropdown">
                        <NavDropdown.Item href="/FG/testedebasesolicitar">SOLICITAÇÃO DE TESTE</NavDropdown.Item>
                        <AccessControl areas={['Qualidade - MP']}>
                          <NavDropdown.Item href="/FG/laboratorio/testedebase">LABORATÓRIO - TESTE DE BASE</NavDropdown.Item>
                        </AccessControl>
                      </NavDropdown>
                      <NavDropdown.Item href="/FG/dpp">DIGITAL PRODUCT PASSPORT</NavDropdown.Item>
                    </AccessControl>
                  </NavDropdown>
                </AccessControl>
              </Nav>
            </Nav>
          </AccessControl>

          <Nav>
            <Nav.Link style={{ display: 'flex', gap: '16px' }}>
              {
                CookiesService.isUserAdmin() &&
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowControleAcessoModal(true)}
                  style={{ textWrap: 'nowrap' }}
                >
                  Controle de Acesso
                </Button>
              }
              <Button
                variant="outline-secondary"
                onClick={clearSession}
              >
                Sair
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
