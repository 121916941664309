import { Modal, Button } from "react-bootstrap";
import { useFGReportsFarmGlobal } from "./ReportsFarmGlobalContext";
import styles from './ReportsFarmGlobal.module.scss';
import { toBrDateWithTime } from "../../../utils/dateUtils";
import MaterialIconsReact from "material-icons-react";
import ModalArquivosFarmGlobal from "./fg_reports_file_component";

const ModalReportDocs = () => {
  const { showDocsModal, selectedDocs, selectedItem, handleCloseDocsModal, handleShowPDF, handleInputAdditionalFiles, chemicalTestFiles, physicalTestFiles, inspectionFiles, removeFile, setShowConfirmDeleteModal } = useFGReportsFarmGlobal();

  return (
    <Modal
      show={showDocsModal}
      onHide={handleCloseDocsModal}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Doc List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className={styles.product_wrapper}>
          <div className={styles.product_info}>
            <div><span className={styles.pi_label}>Style</span> <span className={styles.pi_value}>{selectedItem.style}</span></div>
            <div><span className={styles.pi_label}>Season</span> <span className={styles.pi_value}>{selectedItem.season}</span></div>
            <div><span className={styles.pi_label}>Supplier</span> <span className={styles.pi_value}>{selectedItem.supplier}</span></div>
            <div><span className={styles.pi_label}>Subsupplier</span> <span className={styles.pi_value}>{selectedItem.subsupplier || '-'}</span></div>
          </div>
        </section>
        <section style={{ marginTop: '20px' }}>
          <div className={styles.file_column_wrapper}>
            <div style={{ width: '32%' }}>
              <div style={{ marginBottom: '6px', fontWeight: '500' }}>Chemical Test Files</div>
              <div className={styles.file_wrapper_outer}>
                {selectedDocs?.chemical_test_files?.map((item, index) => {
                  return (
                    <div
                      key={`ctf_${item.id_arquivo}`}
                      className={styles.file_wrapper_inner}
                      onClick={() => handleShowPDF(item.nome_arquivo)}
                    >
                      <span className={styles.file_span}>
                        {item.aprovado ?
                          <MaterialIconsReact icon="check_circle" size={20} color='#35B86F' /> :
                          <MaterialIconsReact icon="error" size={20} color='#F23C3C' />
                        }
                      </span>
                      <span className={styles.file_span}><MaterialIconsReact icon="description" size={20} color='#3F88FD' /></span>
                      <span>{toBrDateWithTime(item.data_registro)}</span>
                      <i
                        className={styles.delete_btn_wrapper}
                        onClick={e => { e.stopPropagation(); removeFile(item) }}
                      >
                        <MaterialIconsReact icon="delete" size={20} color='#F23C3C' />
                      </i>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className={styles.vertical_separator_max} />

            <div style={{ width: '32%' }}>
              <div style={{ marginBottom: '6px', fontWeight: '500' }}>Physical Test Files</div>
              <div className={styles.file_wrapper_outer}>
                {selectedDocs?.physical_test_files?.map((item, index) => {
                  return (
                    <div
                      key={`ptf_${item.id_arquivo}`}
                      className={styles.file_wrapper_inner}
                      onClick={() => handleShowPDF(item.nome_arquivo)}
                    >
                      <span className={styles.file_span}>
                        {item.aprovado ?
                          <div><MaterialIconsReact icon="check_circle" size={20} color='#35B86F' /></div> :
                          <div><MaterialIconsReact icon="error" size={20} color='#F23C3C' /></div>
                        }
                      </span>
                      <span className={styles.file_span}><MaterialIconsReact icon="description" size={20} color='#3F88FD' /></span>
                      <span>{toBrDateWithTime(item.data_registro)}</span>
                      <i
                        className={styles.delete_btn_wrapper}
                        onClick={e => { e.stopPropagation(); removeFile(item) }}
                      >
                        <MaterialIconsReact icon="delete" size={20} color='#F23C3C' />
                      </i>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className={styles.vertical_separator_max} />

            <div style={{ width: '32%' }}>
              <div style={{ marginBottom: '6px', fontWeight: '500' }}>Inspection Files</div>
              <div className={styles.file_wrapper_outer}>
                {selectedDocs?.inspection_files?.map((item, index) => {
                  return (
                    <div
                      key={`if_${item.id_arquivo}`}
                      className={styles.file_wrapper_inner}
                      onClick={() => handleShowPDF(item.nome_arquivo)}
                    >
                      <span className={styles.file_span}>
                        {item.aprovado ?
                          <MaterialIconsReact icon="check_circle" size={20} color='#35B86F' /> :
                          <MaterialIconsReact icon="error" size={20} color='#F23C3C' />
                        }
                      </span>
                      <span className={styles.file_span}><MaterialIconsReact icon="description" size={20} color='#3F88FD' /></span>
                      <span>{toBrDateWithTime(item.data_registro)}</span>
                      <i
                        className={styles.delete_btn_wrapper}
                        onClick={e => { e.stopPropagation(); removeFile(item) }}
                      >
                        <MaterialIconsReact icon="delete" size={20} color='#F23C3C' />
                      </i>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <section className={styles.file_comp_wrapper}>
            <div className={styles.separator} />
            <span style={{ fontWeight: '500' }}>Add Extra Files</span>
            <ModalArquivosFarmGlobal editMode={true} />
            <div className={styles.separator} />
            <Button
              variant={'success'}
              style={{ width: '200px', marginLeft: 'auto' }}
              onClick={handleInputAdditionalFiles}
              disabled={
                chemicalTestFiles.length === 0 &&
                physicalTestFiles.length === 0 &&
                inspectionFiles.length === 0
              }
            >
              Confirm - Add Extra Files
            </Button>
          </section>
        </section>
      </Modal.Body>
      <Modal.Footer>
      <Button
          variant={'danger'}
          style={{ width: '200px', marginRight: 'auto' }}
          onClick={() => setShowConfirmDeleteModal(true)}
        >
          Remove Report
        </Button>
        <Button
          variant={'secondary'}
          style={{ width: '200px' }}
          onClick={handleCloseDocsModal}
        >
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalReportDocs;
